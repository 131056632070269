module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="nei-banner">\r\n    <img src="' +
((__t = ( require('./images/subsidiary-banner.png') )) == null ? '' : __t) +
'" />\r\n</div>\r\n<div class="nei-top">\r\n    <div class="nei-humburger">\r\n        <a href="index.html">首页</a>\r\n        <span>></span>\r\n        <a href="about.html">走进ok138太阳集团</a>\r\n        <span>></span>\r\n        <a href="honor-details.html" class="active">子公司布局</a>\r\n        <span>></span>\r\n    </div>\r\n</div>';

}
return __p
}